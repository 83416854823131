<template>
  <v-card class="teros-elevation mt-2" :color="tableColor">
    <v-card-title class="px-5  mb-2  pb-0 v-title-cadastro">
      <span class="h5 font-weight-bold">
        Projeto: <span class="">{{ projeto.titulo }}</span>
      </span>
    </v-card-title>
    <MasterDetail
      class="master-detail"
      :actionBarButtons="actionBarButtons"
      :cols="cols"
      :opts="opts"
      :hasNewButton="false"
      :canDelete="false"
      :canEdit="false"
      :hasFilter="true"
      :hasExportCSV="false"
      :hasExportXLS="false"
      :customResource="customResource"
      :lastRowData="lastRowData"
      :contextOptions="contextOptions"
    >
      <DropdownCaptacao></DropdownCaptacao>
    </MasterDetail>
  </v-card>
</template>

<script>
import { cloneDeep, isFinite, toNumber } from "lodash";
import { mapGetters } from "vuex";

export default {
  components: {
    MasterDetail: () => import("@/components/master-detail.vue"),
    DropdownCaptacao: () => import("@/components/dropdown-captacao.vue")
  },
  computed: {
    ...mapGetters(["clientId"]),
    actionBarButtons() {
      // const router = this.$router;
      // const projetoId = this.projetoId;
      const cols = this.cols;
      const exportXlsx2 = this.exportXlsx2;
      const projectTitle = this.projeto.titulo;
      const rows = this.rows;
      const buttons = [
        // {
        //   text: "Remanejar rubricas",
        //   icon: "mdi-plus-box-outline",
        //   show: true,
        //   action() {
        //     router.push({
        //       name: "remanejamento-rubricas",
        //       params: { id: projetoId }
        //     });
        //   }
        // },
        {
          text: "Exportar XLSX",
          icon: "mdi-file-excel-outline",
          show: true,
          action() {
            const lastRowIndex = rows.length + 1 ?? 1;
            const exportOptions = {
              titulo: `Resumo (PREV/REAL) do projeto ${projectTitle}`,
              styles: [1, lastRowIndex].reduce(
                (acc, key) => ({
                  ...acc,
                  [key]: {
                    font: {
                      bold: true
                    }
                  }
                }),
                {}
              )
            };
            const header = cols.map(({ name }) => name);
            const content = rows.map(row =>
              cols.map(({ key }) => (key in row ? row[key] : ""))
            );
            const report = [header, ...content];
            exportXlsx2(report, exportOptions);
          }
        }
      ];
      return buttons.filter(({ show }) => show);
    },
    contextOptions() {
      const router = this.$router;
      const projetoId = this.projetoId;
      let buttons = [
        {
          name: "Remanejar rubrica",
          show: true,
          cb(row) {
            router.push({
              name: "remanejamento-rubricas-edicao",
              params: { id: projetoId, rubrica: row.rubrica_id }
            });
          }
        }
      ];
      return buttons.filter(({ show }) => show);
    },
    projetoId() {
      return this.$route.params.id;
    },
    cols() {
      return [
        {
          key: "rubrica",
          name: "Rubrica",
          type: this.$fieldTypes.TEXT
        },
        {
          key: "valor_previsto",
          name: "Orçamento aprovado",
          type: this.$fieldTypes.MONEY
        },
        {
          key: "valor_anterior",
          name: "Período anterior",
          type: this.$fieldTypes.MONEY
        },
        {
          key: "valor_contrapartida_anterior",
          name: "Contrapartida anterior",
          type: this.$fieldTypes.MONEY
        },
        {
          key: "valor_realizado",
          name: "Realizado no período",
          type: this.$fieldTypes.MONEY
        },
        {
          key: "valor_contrapartida",
          name: "Contrapartida no período",
          type: this.$fieldTypes.MONEY
        },
        {
          key: "total_realizado",
          name: "Total realizado",
          type: this.$fieldTypes.MONEY
        },
        {
          key: "saldo_disponivel",
          name: "Saldo disponível",
          type: this.$fieldTypes.MONEY
        }

        // {
        //     key: 'descricao',
        //     name: 'Descrição',
        //     type: this.$fieldTypes.TEXT,
        //     truncate: this.visibilidade.VL_DESCRICAO ? false : true,
        //     wrap: this.visibilidade.VL_DESCRICAO ? true : false,
        //     icon: {
        //         text: `mdi-unfold-${
        //             this.visibilidade.VL_DESCRICAO ? "less" : "more"
        //         }-vertical`,
        //         style: `
        //             background-color: ${ this.isClient ? "#6BB4BA" : "#81B4EA" };
        //             border-radius: 50%;
        //             color: #000;
        //             padding: 2px;
        //             margin-left: 2px;`,
        //         click: () => {
        //             this.visibilidade.VL_DESCRICAO = !this.visibilidade.VL_DESCRICAO;
        //         },
        //     },
        //     width: this.visibilidade.VL_DESCRICAO ? '350px' : '10px',
        //     colSize: 4,
        // },
        // {
        //     key: 'finalidade',
        //     name: 'Finalidade',
        //     type: this.$fieldTypes.TEXT,
        //     highlighted: true,
        //     truncate: this.visibilidade.VL_DESCRICAO ? false : true,
        //     wrap: this.visibilidade.VL_DESCRICAO ? true : false,
        //     icon: {
        //         text: `mdi-unfold-${
        //             this.visibilidade.VL_FINALIDADE ? "less" : "more"
        //         }-vertical`,
        //         style: `
        //             background-color: ${ this.isClient ? "#6BB4BA" : "#81B4EA" };
        //             border-radius: 50%;
        //             color: #000;
        //             padding: 2px;
        //             margin-left: 2px;`,
        //         click: () => {
        //             this.visibilidade.VL_FINALIDADE = !this.visibilidade.VL_FINALIDADE;
        //         },
        //     },
        //     width: this.visibilidade.VL_FINALIDADE ? '350px' : '10px',
        //     colSize: 4,
        // },
      ];
    },
    resourceOrcamento() {
      return this.apiResource(
        `v1/captacao/${this.clientId}/orcamentos/${this.projetoId}/detalhado`
      );
    },
    resourceUrl() {
      return this.apiResource(
        `v1/captacao/${this.clientId}/orcamentos/${this.projetoId}/comprovacao`
      );
    },
    customResource() {
      const resource = this.resourceUrl;
      const that = this;
      return {
        ...resource,
        get() {
          return resource.get().then(result => {
            that.lastRowData = result.reduce(
              (acc, curr) => ({
                ...acc,
                valor_previsto:
                  Number.parseFloat(acc.valor_previsto) +
                  Number.parseFloat(curr.valor_previsto),
                valor_anterior:
                  Number.parseFloat(acc.valor_anterior) +
                  Number.parseFloat(curr.valor_anterior),
                valor_realizado:
                  Number.parseFloat(acc.valor_realizado) +
                  Number.parseFloat(curr.valor_realizado),
                valor_contrapartida:
                  Number.parseFloat(acc.valor_contrapartida) +
                  Number.parseFloat(curr.valor_contrapartida),
                valor_contrapartida_anterior:
                  Number.parseFloat(acc.valor_contrapartida_anterior) +
                  Number.parseFloat(curr.valor_contrapartida_anterior),
                total_realizado:
                  Number.parseFloat(acc.total_realizado) +
                  Number.parseFloat(curr.total_realizado),
                saldo_disponivel:
                  Number.parseFloat(acc.saldo_disponivel) +
                  Number.parseFloat(curr.saldo_disponivel)
              }),
              {
                rubrica: "Total",
                valor_previsto: 0,
                valor_anterior: 0,
                valor_realizado: 0,
                valor_contrapartida: 0,
                valor_contrapartida_anterior: 0,
                total_realizado: 0,
                saldo_disponivel: 0
              }
            );

            that.rows = result.concat(that.lastRowData).map(row => {
              const target = cloneDeep(row);

              Object.entries(target).forEach(([key, value]) => {
                const parsedValue = toNumber(value);

                if (isFinite(parsedValue)) {
                  target[key] = parsedValue;
                }
              });

              return target;
            });

            return result;
          });
        }
      };
    }
  },
  data() {
    return {
      visibilidade: {
        VL_FINALIDADE: false,
        VL_DESCRICAO: false
      },
      opts: {
        rubricas: [],
        projetos: [],
        descricoes: [],
        parcelas: []
      },
      projeto: {
        titulo: ""
      },
      numero_parcela: null,
      parcelas: [],
      lastRowData: {},
      rows: []
    };
  },
  created() {
    this.getProjetoSelecionado();
    this.getOrcamento();
  },
  methods: {
    getProjetoSelecionado() {
      return this.apiResource(
        `/v1/captacao/projetos/${this.clientId}/${this.projetoId}`
      )
        .get()
        .then(result => {
          return (this.projeto = result);
        });
    },
    getOrcamento() {
      const resource = this.resourceOrcamento;
      const that = this;
      resource.get().then(result => {
        that.parcelas = result.parcelas;
      });
    }
  },
  watch: {
    clientId() {
      this.$router.push({ name: "projetos-captacao" });
    }
  }
};
</script>

<style lang="scss" scoped>
.master-detail::v-deep .v-data-table__wrapper {
  height: calc(-300px + 100vh) !important;
}
</style>
